// Import Cores
import React from "react";


//Image Import
import ImgDar from "../../assets/img/products/Dar.svg";
import ImgPatientVoice from "../../assets/img/products/PatientVoice.svg";
import ImgEGP from "../../assets/img/products/EGP.svg";
import ImgPathmedica from "../../assets/img/products/Pathmedica.svg";
import ImgFoodparcel from "../../assets/img/products/Foodparcel.svg";
import ImgBookFii from "../../assets/img/products/bookfii.svg";

function ProductSection() {
  return (
    <>
      {/* Partners Section Start */}
      <section id="products">
        <div className="container">
          <div className="row">
            <div className="products-content text-center" data-aos-duration="1000" data-aos="fade-up">
              <h2 className="sec-title mb-5">Product Development</h2>
              <div className="products-logo mt-5">
                <img className="img-fluid" src={ImgDar} alt="Dar" />
                <img className="img-fluid" src={ImgPatientVoice} alt="PatientVoice" />
                <img className="img-fluid" src={ImgEGP} alt="EGP" />
                <img className="img-fluid" src={ImgPathmedica} alt="Pathmedica" />
                <img className="img-fluid" src={ImgFoodparcel} alt="Foodparcel" />
                <img className="img-fluid" src={ImgBookFii} alt="BookFii" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Partners Section End */}
    </>
  );
}
export default ProductSection;
