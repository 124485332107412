// Import Cores
import React from "react";

function ContactUsPageSection() {
    return (
        <>
            {/* Contact Page Section Start */}
            <section id="contact">
                <div class="container">
                    <div class="row ">
                        <div class="col-md-4 d-flex align-items-center">
                            <div class="contect-content" data-aos-duration="1000" data-aos="fade-right">
                                <h2 class="sec-title mb-4">Innovating Technology to Improve Productivity </h2>
                            </div>
                        </div>
                        <div class="col-md-8 d-flex align-items-center">
                            <div class="contact-form" data-aos-duration="1000" data-aos="fade-left">
                                <form class="from-wrapper" id="contact_form">
                                    <div class="form-contact" id="form">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="name" name="name" placeholder="Name" required data-error="Please enter your name"/>
                                                        <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <input type="text" class="form-control" id="organisation" name="organisation" placeholder="Organisation" required data-error="Please enter your organisation"/>
                                                        <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <input type="email" placeholder="Email Address" id="email" class="form-control" name="email" required data-error="Please enter your email"/>
                                                        <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <input type="tel" placeholder="Telephone" id="phone" class="form-control" name="phone" required data-error="Please enter your email"/>
                                                        <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <textarea class="form-control" id="description" placeholder="Description" rows="8" data-error="Write your message" required></textarea>
                                                    <div class="help-block with-errors"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <div class="submit-button text-center">
                                                    <button class="btn btn-submit" id="submit" type="submit">SUBMIT</button>
                                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                                    <div class="clearfix"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* Contact Page Section End */}
        </>
    );
}
export default ContactUsPageSection;
