// Import Core
import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

function CopyRightFooter() {

  return (
    <>    
      { /* Copyright Footer */ }
      <section id="copyright_footer">
        <div className="container">
        <div className="text-center py-2">
                <div className="copyright">
                    &copy; Copyright <span><Link to="/" > <strong>STRATEGICA</strong> </Link></span> {new Date().getFullYear()}
                </div>
            </div>
        </div>
    </section>
    { /* Copyright Footer End */ }
    <ScrollToTop smooth  top="500" color="white" data-aos-duration="1000" data-aos="fade-up"/> 
    </>
  );
}
export default CopyRightFooter;

