// Import Core
import React from "react";

// Sections for this Page
import Page404Section from "../components/sections/Page404Section.js";

function Page404() {
  return (
    <>
      <main id="main" className="main">
        <Page404Section />
      </main>
    </>
  );
}
export default Page404;
