// Import Cores
import React from "react";

function SubPageHeader(props) {
  return (
    <>
      {/* Page Header Start */}
      <section id="page_title">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <div class="pt-content" data-aos-duration="2000" data-aos="fade-up">
                <h1 class="pt-name"> {props.pageTitle} </h1>
              </div>
            </div>
            <div class="col-md-4">
            </div>
          </div>
        </div>
      </section>
      {/* Page Header End */}
    </>
  );
}
export default SubPageHeader;
