// Import Core
import React from "react";


// Sections for this Page
import ThankYouPageSection from "../components/sections/ThankYouPageSection.js";

function ThankYou() {
  return (
    <>
      <main id="main" className="main">
        <ThankYouPageSection />
      </main>
    </>
  );
}
export default ThankYou;