// Import Core
import React from "react";

// Import Components
import SubPageHeader from "../components/headers/SubPageHeader.js";

// Sections for this Page
import ContactUsPageSection from "../components/sections/ContactUsPageSection.js";

function Contact() {
  return (
    <>
      <SubPageHeader pageTitle={'Contact Us'} />
      <main id="main" className="main">
        <ContactUsPageSection />
      </main>
    </>
  );
}
export default Contact;
