// Import Cores
import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

// Import Date
import { socialMenus, footerMenus } from "../../data/manu-links";

function MainFooter() {
  return (
    <>
      <footer id="footer" data-aos="fade-up" data-aos-duration="500">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12 footer-logo pb-5 text-start">
                <Link to="/" ><img className="img-fluid" src="logo-Invert.svg" alt="Straregica" /></Link>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 footer-content">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12 footer-address pb-5">
                    <h4 className="mb-4">Address</h4>
                    <p className="text">10 Cliff Parade, Wakefield, WF1 2TA</p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 footer-contact pb-5">
                    <h4 className="mb-4">Email</h4>
                    <p className="text"><a href="mailto:info@strategica.ai" target="_blank" rel="noreferrer" >info@strategica.ai</a></p>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 footer-links">
                    <h4 className="mb-4">Links</h4>
                    <ul className="list-unstyled">
                      {footerMenus.map((footerLink) => { return (<li><Link key={footerLink.id} to={footerLink.url}>{footerLink.text}</Link></li>) })}
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 social-links pb-5 mt-3">
                    <div className="text-lg-end">
                      {socialMenus.map((socialLink) => { return (<a key={socialLink.id} href={socialLink.url} target="_blank" rel="noreferrer" ><i className={socialLink.text}></i></a>) })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default MainFooter;
