// Import Cores
import React from "react";

function MainPageHeader() {
  return (
    <>
      {/* Hero Section Start */}
      <section id="hero">
        <div className="container">
          <div className="row col-rev">
            <div className="col-md-8">
              <div className="hero-content" data-aos-duration="2000" data-aos="fade-up">
                <h1 className="hero-title">Innovating Technology<br />to Improve Productivity</h1>
              </div>
            </div>
            <div className="col-md-4">
            </div>
          </div>
        </div>
      </section>
      {/* Hero Section End */}
    </>
  );
}
export default MainPageHeader;
