// Import Cores
import React from "react";

function AboutSection() {
  return (
    <>
      {/* About Section Start */}
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="about-content" data-aos-duration="1000" data-aos="fade-up">
              <h2 className="sec-title mb-5">About Us</h2>
              <p>Strategica AI is a technology company which specializes in cloud application development. We have our own in house team of developers and front end designers. Some of our applications include:</p>
						<h5>Patient Voice</h5>
						<p>National patient feedback system in the UK with over 36,000 registered healthcare organisations </p>
						<h5>Bookfii</h5>
						<p>Laboratory test booking system which allows customers/patients to book a healthcare test, have it dispatched and processed in the laboratory in a seamless cloud system</p>
            </div>
          </div>
        </div>
      </section>
      {/* About Section End */}
    </>
  );
}
export default AboutSection;
