// Import Cores
import React from "react";

//Image Import
import ImgStrategyConsulting from "../../assets/img/photos/strategy_consulting.svg";
import ImgDataIntelligence from "../../assets/img/photos/data_artificial_intelligence.svg";
import ImgCreativityDesign from "../../assets/img/photos/creativity_experience_design.svg";
import ImgEngineerinTechnology from "../../assets/img/photos/engineering_technology.svg";
import ImgIntegratedMarketing from "../../assets/img/photos/integrated_marketing.svg";
import ImgInnovation from "../../assets/img/photos/innovation.svg";
import ImgPrivateEquityServices from "../../assets/img/photos/private_equity_services.svg";

function ServiceSection() {
    return (
        <>
            {/* Service Section Start */}
            <section id="services">
                <div class="service-items">
                    {/* service Item  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-right">
                                        <img src={ImgStrategyConsulting} alt="Strategy & Consulting" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-left">
                                        <h3 className="service-title pb-5">Strategy & Consulting</h3>
                                        <p className="service-text pb-4">For digital experiences to truly transform brands they must connect business goals, data-driven human insight and future-focussed foresight. Our strategists work with brands to define and deliver integrated visionary thinking
                                            that results in tangible business impact.</p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                    {/* service Item Reverse  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-right">
                                        <h3 className="service-title pb-5">Data & Artificial Intelligence</h3>
                                        <p className="service-text pb-4">Our data insights team combines forensic analysis with Machine Learning tools to help brands make the smartest decisions based on the most robust information.
                                        </p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-left">
                                        <img src={ImgDataIntelligence} alt="Data & Artificial Intelligence" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                    {/* service Item Reverse  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-right">
                                        <img src={ImgCreativityDesign} alt="Creativity & Experience Design" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-left">
                                        <h3 className="service-title pb-5">Creativity & Experience Design</h3>
                                        <p className="service-text pb-4">Whether forming new brands, remarkable brand stories or effortless user experiences, our creative experts produce visionary ideas that delight customers and exceed targets.</p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                    {/* service Item Reverse  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-right">
                                        <h3 className="service-title pb-5">Engineering & Technology</h3>
                                        <p className="service-text pb-4">Robust engineering is essential for your business-critical digital estate. Our engineers develop transformative products by working to the highest coding standards on the leading web platforms.</p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-left">
                                        <img src={ImgEngineerinTechnology} alt="Engineering & Technology" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                    {/* service Item Reverse  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-right">
                                        <img src={ImgIntegratedMarketing} alt="Integrated Marketing" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-left">
                                        <h3 className="service-title pb-5">Integrated Marketing</h3>
                                        <p className="service-text pb-4">
                                            Our integrated marketing team creates the most effective, integrated campaigns across all digital platforms. Data-driven thinking maximises cut-through at every touch point.</p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                    {/* service Item Reverse  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-right">
                                        <h3 className="service-title pb-5">Innovation</h3>
                                        <p className="service-text pb-4">
                                            Today’s rate of technological change is forcing businesses to evolve fast. Our dedicated innovators help brands deploy smarter, more impactful experiences faster than their competition.</p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-left">
                                        <img src={ImgInnovation} alt="Innovation" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                    {/* service Item Reverse  */}
                    <div className="service-item">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="service-img" data-aos-duration="1000" data-aos="fade-right">
                                        <img src={ImgPrivateEquityServices} alt="Private Equity Services" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="service-content" data-aos-duration="1000" data-aos="fade-left">
                                        <h3 className="service-title pb-5">Private Equity Services</h3>
                                        <p className="service-text pb-4">Our integrated approach helps you accurately assess business value and identify upside potential by combining robust Digital Due Diligence and technology assessments with full-circle digital transformation services.</p>
                                        <a className="btn service-btn" href="/">Explore</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* service Item End  */}
                </div>
            </section>
            {/* Service Section End */}
        </>
    );
}
export default ServiceSection;
