export const navMenus= [
    {
      id: 1,
      text: 'Home',
      url: '/#',
    },
    {
      id: 2,
      text: 'About Us',
      url: '/#about',
    },
    {
      id: 3,
      text: 'Services',
      url: '/#services',
    },
    {
      id: 4,
      text: 'Products',
      url: '/#products',
    },
    {
      id: 5,
      text: 'Partners',
      url: '/#partners',
    },
    {
      id: 6,
      text: 'Contact Us',
      url: '/contact/#',
    },
  ];

  export const socialMenus= [
    {
      id: 1,
      name: 'Facebook',
      text: 'fab fa-facebook',
      url: 'https://facebook.com/',
    },
    {
      id: 2,
      name: 'Twitter',
      text: 'fab fa-twitter',
      url: 'https://twitter.com/',
    },
    {
      id: 3,
      name: 'Instagram',
      text: 'fab fa-instagram',
      url: 'https://instagram.com/',
    },
    {
      id: 4,
      name: 'LinkedIn',
      text: 'fab fa-linkedin',
      url: 'https://linkedin.com/',
    },
  ];

  export const footerMenus= [
    {
      id: 1,
      text: 'Home',
      url: '/#',
    },
    {
      id: 2,
      text: 'About Us',
      url: '/#about',
    },
    {
      id: 3,
      text: 'Services',
      url: '/#services',
    },
    {
      id: 4,
      text: 'Contact Us',
      url: '/contact/#',
    },
  ];
