// Import Cores
import React from "react";
import { Link } from "react-router-dom";

function ThankYouPageSection() {
    return (
        <>
            {/* Thank You Page Section Start */}
            <section id="thankYou">
                <div class="container">
                    <div class="row ">
                        <div class="col-12 d-flex align-items-center">
                            <div class="thank-you-content text-center" data-aos-duration="1000" data-aos="fade-up">
                                <div className="contant_box_thank_you">
                                    <h2 class="sec-title mb-4">Thank You </h2>
                                    <p>We Will Contact you soon!</p>
                                    <Link to="/#" className="link_thank_you">Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            {/* Thank You Page Section End */}
        </>
    );
}
export default ThankYouPageSection;
