// Import Cores
import React from "react";

//Image Import
import ImgAzure from "../../assets/img/partners/azure.svg";
import ImRackSpace from "../../assets/img/partners/rackspace.svg";
import ImgGoogle from "../../assets/img/partners/Google.svg";
import ImgSitecore from "../../assets/img/partners/Sitecore.svg";
import ImgProgress from "../../assets/img/partners/Progress.svg";
import ImgRocketFuel from "../../assets/img/partners/Rocketfuel.svg";
import ImgDrupal from "../../assets/img/partners/Drupal.svg";
import ImgEPL from "../../assets/img/partners/epl.svg";
import ImgSAP from "../../assets/img/partners/sap.svg";
import ImgUmbraco from "../../assets/img/partners/umbraco.svg";
import ImgWordPress from "../../assets/img/partners/wordpress.svg";
import ImgAWS from "../../assets/img/partners/AWS.svg";

function PartnerSection() {
  return (
    <>
      {/* Partners Section Start */}
      <section id="partners">
        <div className="container">
          <div className="row">
            <div className="partners-content text-center" data-aos-duration="1000" data-aos="fade-up">
              <h2 className="sec-title mb-5">Partners</h2>
              <div className="partners-logo mt-5">
                <img className="img-fluid" src={ImgAzure} alt="azure" />
                <img className="img-fluid" src={ImRackSpace} alt="rackspace" />
                <img className="img-fluid" src={ImgGoogle} alt="Google" />
                <img className="img-fluid" src={ImgSitecore} alt="Sitecore" />
                <img className="img-fluid" src={ImgProgress} alt="Progress" />
                <img className="img-fluid" src={ImgRocketFuel} alt="Rocketfuel" />
                <img className="img-fluid" src={ImgDrupal} alt="Drupal" />
                <img className="img-fluid" src={ImgEPL} alt="epl" />
                <img className="img-fluid" src={ImgSAP} alt="sap" />
                <img className="img-fluid" src={ImgUmbraco} alt="umbraco" />
                <img className="img-fluid" src={ImgWordPress} alt="wordpress" />
                <img className="img-fluid" src={ImgAWS} alt="AWS" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Partners Section End */}
    </>
  );
}
export default PartnerSection;
