// Import Core
import React from "react";

// Import Components
import MainPageHeader from "../components/headers/MainPageHeader.js";

// Sections for this Page
import AboutSection from "../components/sections/AboutSection.js";
import ServiceSection from "../components/sections/ServiceSection.js";
import ProductSection from "../components/sections/ProductSection.js";
import PartnerSection from "../components/sections/PartnerSection.js";

function Home() {
  return (
    <>
      <MainPageHeader />
      <main id="main" className="main">
        <AboutSection />
        <ServiceSection />
        <ProductSection />
        <PartnerSection />
      </main>
    </>
  );
}
export default Home;
