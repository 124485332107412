/*
=========================================================
* Straregica - v1.0
* Template URL: https://zohirs.com
* Author: Zohir
=========================================================
*/

// Import Core
import React from "react";
import AOS from 'aos';

// Import Styles
import 'aos/dist/aos.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

// import pages
import Main from "./pages/Main.js";

function App() {
  AOS.init();
  return (
      < Main />
  );
}
export default App;


