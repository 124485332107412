// Import Cores
import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

// Import Date
import { socialMenus, navMenus } from "../../data/manu-links";

function MainNavBar() {
  const [click, setClick] = React.useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  return (
    <>
      <header id="header" className="main-header fixed-top" data-aos="fade-down" data-aos-duration="500">
        <div className={click ? "navigation-active container" : "container"} >
          <div className="row">
            <div className="col-12 nav-bar">
              <h1 className="logo">
                <Link to="/"><img className="img-fluid" src="logo.svg" alt="" /></Link>
              </h1>
              <nav id="navbar" className="nav-menu d-flex align-content-center" >
                <ul className="main-nav">
                {navMenus.map((navLink) => {
                    return (
                      <li><Link spy={true} smooth={true} offset={120} duration={1000} className="nav-link scrollto" activeClass="active" key={navLink.id} onClick={click ? handleClick : null} to={navLink.url}>{navLink.text}</Link></li>                    )
                  })}
                </ul>
                <ul className="social-nav">
                  {socialMenus.map((socialLink) => {
                    return (
                      <li><a key={socialLink.id} onClick={click ? handleClick : null} href={socialLink.url} target="_blank" rel="noreferrer" ><i className={socialLink.text}></i></a></li>
                    )
                  })}
                </ul>
              </nav>
              <div className="nav-mobile" onClick={handleClick}>
                <button id="nav-button" className="nav-icon">
                  <div onClick={() => Close()} ></div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default MainNavBar;
