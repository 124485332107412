/*
=========================================================
* Straregica - v1.0
* Template URL: https://zohirs.com
* Author: Zohir
=========================================================
*/

// Import Core
import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";


// Import Components
import MainNavBar from "../components/navbars/MainNavBar.js";
import MainFooter from "../components/footers/MainFooter.js";
import CopyRightFooter from "../components/footers/CopyRightFooter.js";

// import pages
import Home from "./Home.js";
import ThankYou from "./ThankYou.js";
import Contact from "./Contact.js";
import Page404 from "./Page404.js";

function Main() {
    return (
        <>
            <BrowserRouter>
                <div className="wrapper">
                    <MainNavBar />
                    <Routes>
                        <Route path="/thank-you" element={<ThankYou />} >
                        </Route>
                        <Route path="/contact" element={<Contact />} >
                        </Route>
                        <Route path="*" element={<Page404 />} >
                        </Route>
                        <Route path="/" exact element={<Home />} >
                        </Route>
                    </Routes>
                    <MainFooter />
                    <CopyRightFooter />
                </div>
            </BrowserRouter>
        </>
    );
}
export default Main;


