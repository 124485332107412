// Import Cores
import React from "react";
import { Link } from "react-router-dom";

function Page404Section() {
    return (
        <>
            {/* Page404Section Section Start */}
            <section id="p_404" className="page_404">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="p-404-content text-center" data-aos-duration="1000" data-aos="fade-up">
                                <h1 className="sec-title mb-5">404</h1>
                                <div className="contant_box_404">
                                    <h3>Look like you're lost</h3>
                                    <p>the page you are looking for not avaible!</p>
                                    <Link to="/#" className="link_404">Go to Home</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Page404Section Section End */}
        </>
    );
}
export default Page404Section;
